import {
    apiUrl
} from './env';
const axios = require('axios');
import { getStore, setStore } from "@/common/utils/utils";
//初始化时即在本机生成客户端ID

//本地随机值 16字节
const getNum = () => {
    var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var nums = "";
    for (var i = 0; i < 16; i++) {
        var id = parseInt(Math.random() * 61);
        nums += chars[id];
    }
    return nums;
}
let uid = getStore('uid')
if (uid == null) {
    uid = getNum();
    setStore('uid', uid)
}




function request(config) {
    let url = apiUrl + config.uri;
    const method = config.method || 'GET';
    var queryData = config.data;

    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    let token = getStore('token')
    if (token !== null) {
        headers['Authorization'] = token
    }
    let opt = {
        baseURL: apiUrl,
        headers: headers,
        transformRequest: [function (data) {
            let ret = ''
            let temp = Object.keys(data);
            for (let it in temp) {
                ret += encodeURIComponent(temp[it]) + '=' + encodeURIComponent(data[temp[it]]);
                if (it != temp.length - 1) {
                    ret += '&';
                }
            }
            return ret
        }]
    };
    if (method == 'GET' && queryData) {
        url += '?' + opt.transformRequest[0](queryData)
    }

    const HTTP = axios.create(opt);
    HTTP.interceptors.response.use(
        response => {
            //拦截响应，做统一处理 
            if (response.data.code) {
                console.log(response, url)
            }
            return response
        },
        //接口错误状态处理，也就是说无响应时的处理
        error => {
            alert(error.response.data.message)
            return Promise.reject(error.response.status) // 返回接口返回的错误信息
        })
    return new Promise((resolve, reject) => {
        HTTP({
            method: method,
            url: url,
            data: queryData
        }).then(res => {
            let data = res.data;
            resolve(data);
        }).catch(err => {
            reject(err)
        });

    });


}
export default {
    request,
    /**
     * 供外部调用直接设置 api 请求的 AccessToken
     * 此修改永久有效
     *
     * @param {String} token 设为null则代表删除token
     */
    get(uri, data) {
        return request({
            uri,
            method: 'GET',
            data
        });
    },
    delete(uri) {
        return request({
            uri,
            method: "DELETE"
        });
    },
    head(uri) {
        return request({
            uri,
            method: "HEAD"
        });
    },
    post(uri, data) {
        return request({
            uri,
            method: "POST",
            data
        });
    },
    put(uri, data) {
        return request({
            uri,
            method: "PUT",
            data
        });
    },
    patch(uri, data) {
        return request({
            uri,
            method: "PATCH",
            data
        });
    },

};