<template>
    <div class="wrapper home">
        <div class="m-warning flex flex-yc "><span class="icon-warn iconfont"></span>以下数据供参考，代理人是否进行退票、改签等操作，请结合数据自行判断。
        </div>
        <div class="b-box" v-if="info!=null && info.flight!=null">
            <p class="b-title">航班动态信息</p>
            <FlighInfo :flight="info.flight"></FlighInfo>
            <div class="b-tip">
                <p>*“航班是否延误”字段，按照各航司延误标准判断，满足航司延误标准标识为【延误】，不满足标准，则为空值</p>
            </div>
        </div>
        <div class="b-box" v-if="info!=null && info.prevFlight!=null">
            <p class="b-title">前序航班信息</p>
            <FlighInfo :flight="info.prevFlight" type="prev"></FlighInfo>
            <div class="b-tip" v-if="info.prevFlight!=null">
                <p v-if=" info.prevFlight.passTime">*前序航班在{{form.depCode}}的最少过站时间为{{info.prevFlight.passTime}}分钟</p>
                <p v-else>暂无无前序航班</p>
            </div>
        </div>
        <template v-if="info!=null && info.history!=null">
            <div class="b-box">
                <p class="b-title">航班历史信息（7天内）</p>
                <table class="b-table" cellpadding="0" cellspacing="0" border="0" v-if="info!=null">
                    {{void (history = info.history)}}
                    <thead>
                        <tr>
                            <th>航班号</th>
                            <th>出发机场</th>
                            <th>到达机场</th>
                            <th>取消率</th>
                            <th>延误率 </th>
                            <th>出发平均延误时长 </th>
                            <th>到达平均延误时长</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>

                            <td>{{history.fnum}}</td>
                            <td>
                                <div class="airport">
                                    <p class="name ">{{history.depName}}</p>{{history.depCode}}
                                </div>
                            </td>
                            <td>
                                <div class="airport">
                                    <p class="name">{{history.arrName}}</p>{{history.arrCode}}
                                </div>
                            </td>
                            <td>{{history.cancelRate}}</td>
                            <td>{{history.delayRate}}</td>
                            <td>{{history.depAvgDelayTime}}min</td>
                            <td>{{history.arrAvgDelayTime}}min </td>
                        </tr>

                    </tbody>
                </table>
                <div class="b-tip">
                    <p>* 1. 取消率 = 历史7天取消航班量 / 历史7天计划航班量 *100%</p>
                    <p>&nbsp;&nbsp;2. 延误率 = 历史7天延误航班量 / 历史7天执飞航班量 *100%</p>
                </div>
            </div>
            <div class="b-box">
                <p class="b-title">历史信息详情</p>
                <table class="b-table" cellpadding="0" cellspacing="0" border="0" v-if="info!=null">
                    {{void (sevenDayOntime = info.history.sevenDayOntime)}}
                    <thead>
                        <tr>
                            <th>历史日期</th>
                            <th>航班状态</th>
                            <th>航班是否延误</th>
                            <th>出发延误时长</th>
                            <th>到达延误时长 </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in sevenDayOntime" :key="index">
                            <td>{{item.date}}</td>
                            <td>{{item.state}}</td>
                            <td>{{item.isDelay || '--'}}</td>
                            <td>{{item.depDelay}}</td>
                            <td>{{item.arrDelay}}</td>

                        </tr>

                    </tbody>
                </table>
                <div class="b-tip">
                    <p>* 1.“航班是否延误”字段，按照各航司延误标准判断，满足航司延误标准标识为【是】，不满足标准，则为空值；</p>
                    <p>&nbsp;&nbsp;2.
                        出发、到达延误时长，有【撤】、【上】标识的，表示有撤轮挡、上轮挡时间，按照撤轮挡时间-计划起飞时间、上轮挡时间-计划到达时间，计算延误时长；数值为正值，表示比计划时间晚点，数值为负值，表示比计划时间提前
                    </p>
                </div>
            </div>
        </template>

        <div class="b-box" v-if="info!=null && info.warnings!=null">
            <p class="b-title">机场特情</p>
            <table class="b-table" cellpadding="0" cellspacing="0" border="0" v-if="info!=null">
                {{void (warnings = info.warnings)}}
                <thead>
                    <tr>
                        <th width="4%">机场</th>
                        <th width="7%">特情类型</th>
                        <th width="50%">特情内容</th>
                        <th width="6%">开始时间</th>
                        <th width="6%">结束时间 </th>
                        <th width="28%">可能受影响航班 </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in warnings" :key="index">
                        <td>{{item.airport}}</td>
                        <td>{{item.type}}</td>
                        <td>
                            <div class="w-text" v-html="item.content"></div>
                        </td>
                        <td>{{item.starttime}}</td>
                        <td>{{item.endtime}}</td>
                        <td>
                            <div class="w-flight">
                                <div class="fns flex flex-yc">
                                    <p>出港航班:&nbsp;&nbsp;{{getFlight(item.depFlights)}}</p>
                                    <span v-if="Object.keys(item.depFlights).length>0"
                                        @click="isShowWarn(item.depFlights,'dep')">查看详情</span>
                                    <p v-else>--</p>
                                </div>
                                <div class="fns flex flex-yc">
                                    <p>进港航班:&nbsp;&nbsp;{{getFlight(item.arrFlights)}}</p>
                                    <span v-if="Object.keys(item.arrFlights).length>0"
                                        @click="isShowWarn(item.arrFlights,'arr')">查看详情</span>
                                    <p v-else>--</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <WarningsFlights :flights="wFlight" v-if="showWarnFlights" @close="showWarnFlights=false" :type="wFlightType">
        </WarningsFlights>
    </div>
</template>
<script>
    // import { reactive, toRefs, defineAsyncComponent, computed } from 'vue'
    import { reactive, nextTick, toRefs, defineAsyncComponent } from 'vue'
    import api from "@/config/api";
    export default {
        name: 'home',
        components: {
            FlighInfo: defineAsyncComponent(() => import("./components/flight-info")),
            WarningsFlights: defineAsyncComponent(() => import("./components/warning-flights")),
        },

        setup() {
            const state = reactive({
                form: {
                    appId: 100001,
                    fnum: 'CA1239',
                    depCode: 'PEK',
                    arrCode: 'AKU',
                    date: '2022-02-28'
                },
                info: null,
                wFlight: [],
                showWarnFlights: false,
                wFlightType: ''
            })
            nextTick(() => {
                let url = getUrlVars(window.location.href)
                state.form = url.query
                getData(url.query)
            })
            const getUrlVars = (url) => {
                var vars = [];
                var baseUrl = ''
                var index = url.indexOf('?')
                if (index !== -1) {

                    baseUrl = url.slice(0, index)

                }
                var queryArr = url.slice(index + 1).split('&');
                for (var i = 0; i < queryArr.length; i++) {
                    var hashIndex = queryArr[i].indexOf('=')
                    var key = queryArr[i].slice(0, hashIndex)
                    var value = decodeURIComponent(queryArr[i].slice(hashIndex + 1, hashIndex.length))
                    vars[key] = value
                }
                return { url: baseUrl, query: vars }

            }
            const getData = () => {
                api.get('/api/reports/home', state.form).then((res) => {
                    if (res.code == 0) {
                        state.info = res.data
                    } else {
                        alert('error')
                    }

                });
            }
            const isShowWarn = (data, type) => {
                state.wFlight = data;
                state.wFlightType = type
                state.showWarnFlights = !state.showWarnFlights

            }
            const getFlight = (data) => {
                let str = ''
                let l = data.length > 6 ? 6 : data.length
                for (let i = 0; i < l; i++) {
                    str += data[i][0]
                    if (i < l - 1) {
                        str += ','
                    } else {
                        str += '...'
                    }
                }
                return str

            }
            return {
                ...toRefs(state),
                isShowWarn,
                getFlight
            }
        },
    }
</script>