/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}

/**
* 获取localStorage
*/
export const getStore = name => {
    if (!name) return;
    return window.localStorage.getItem(name);
}

/**
* 删除localStorage
*/
export const removeStore = name => {
    if (!name) return;
    window.localStorage.removeItem(name);
}

/**
* 删除sessionStorage
*/
export const removeSessionStore = name => {
    if (!name) return;
    window.sessionStorage.removeItem(name);
}


/**
* 存储sessionStorage
*/
export const setSessionStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.sessionStorage.setItem(name, content);
}

/**
* 获取sessionStorage
*/
export const getSessionStore = name => {
    if (!name) return;
    return window.sessionStorage.getItem(name);
}

/**
 * 拼装url
 * @param {String} url：基础url
 * @param {Object} params：要拼进url的参数对象
 * @return {String}
 * */
export const splicingUrl = (url, params) => {
    params = params || {}

    if (JSON.stringify(params) === '{}') {
        return url
    }

    if (!url.indexOf('/')) {
        url = '/' + url
    }

    if (url.indexOf('?') < 0) {
        url += '?'
    }
    for (let i in params) {
        if (typeof params[i] !== 'function') {
            url += `&${i}=${params[i]}`
        }
    }
    return url.replace('?&', '?')
}

/**
 * 获取url指定参数
 * @param {String} url: 目标url
 * @param {String} keyName: 需要从url获取到的参数key
 * @return {String} string || null
 * */
export const getUrlOneParam = (url, keyName) => {
    let reg = new RegExp('(^|&)' + keyName + '=([^&]*)(&|$)')
    let r = url.search.substr(1).match(reg)
    if (r !== null) return decodeURIComponent(r[2])
    return null
}

/**
 * 获取url全部参数
 * @param {String} url:目标url
 * @return {Object} 全部参数对象
 * */
export const getUrlAllParams = (url) => {
    let qs = (url.search.length > 0 ? url.search.substring(1) : '')
    let args = {}
    let items = qs.length > 0 ? qs.split('&') : []
    for (let data of items) {
        let item = data.split('=')
        let name = decodeURIComponent(item[0])
        let value = decodeURIComponent(item[1])
        if (name.length) {
            args[name] = value
        }
    }
    return args
}

/**
 * 三非判断，（非空字符串、非null，非undefined）
 * @param {any} target:需要判断的值
 * @return boolean
 * */
export const checkThreeType = (target) => {
    if (target !== '' && target !== null && target !== undefined) {
        return true
    }
    return false
}

/**
 * 非空对象
 * @param {Object} target
 * @return {Boolean} 空对象：true 非空对象：false
 */
export const nonEmptyObject = (target) => {
    if (JSON.stringify(target) === '{}') {
        return true
    }
    return false
}

/**
 * 接口数据过滤
 * @param {Object} data: 数据（格式：非空对象）
 * @param {Array} keyLists 要保留字段名的集合
 * @return {Object} result 数据（对象）
 * */
export const DataFiltering = (data, keyLists) => {
    if (JSON.stringify(data) === '{}' || !(keyLists instanceof Array) || !keyLists.length) {
        throwError('TypeError', '参数类型错误', 'DataFiltering')
    }
    let result = {}
    for (let val of keyLists) {
        // eslint-disable-next-line no-prototype-builtins
        if (checkThreeType(val) && data.hasOwnProperty(val)) {
            result[val] = data[val]
        }
    }
    return result
}

/**
 * 抛错
 * @param {String} errorType: 错误类型--可选值:SyntaxError(语法)/ReferenceError(引用)/RangeError(范围)/TypeError(类型)/URLError(URL错误)/EvalError(eval错误)
 * @param {String} errortips: 提示内容(string)
 * @param {String} funcName: 提示错误出现在哪个方法
 * */
const throwError = function (errorType, errortips, funcName) {
    let tips = `Uncaught(in ${funcName})${errortips}`
    switch (errorType) {
        case 'SyntaxError':
            throw new SyntaxError(tips)
        case 'ReferenceError':
            throw new ReferenceError(tips)
        case 'RangeError':
            throw new RangeError(tips)
        // case 'TypeError':
        //   throw new SyntTypeErroraxError(tips)
        // case 'URLError':
        //   throw new URLError(tips)
        case 'EvalError':
            throw new EvalError(tips)
        default:
            throw new Error(tips)
    }
}

/**
 * js双精度计算保留x位小数
 * @param {String} calcuType 计算类型--add/reduce/ride/except  加减乘除
 * @param {String} decPlace 保留小数位
 * @param {Number} operaNum 操作数字
 * @param {Number} operatedNum 被操作数字
 * */
export const addDoublePrecision = (operaNum, operatedNum, calcuType, decPlace) => {
    if (typeof operaNum !== 'number' || typeof operatedNum !== 'number' || typeof calcuType !== 'string') {
        throwError('TypeError', '参数类型错误', 'addDoublePrecision')
    }
    // 去ab的最大小数位
    let result
    let pow
    if (decPlace) {
        pow = Math.pow(10, decPlace)
        // 10的x倍数
        operaNum = operaNum.toFixed(decPlace)
        operatedNum = operatedNum.toFixed(decPlace)
    } else if (decPlace === 0) {
        pow = 1
    } else {
        let operaArr = operaNum.toString().split('.')
        let operatedArr = operatedNum.toString().split('.')
        let numMaxDec = Math.max(operaArr[1] ? operaArr[1].toString().length : 0, operatedArr[1] ? operatedArr[1].toString().length : 0)
        pow = Math.pow(10, numMaxDec)
        decPlace = numMaxDec
    }
    let pOperaNum = operaNum * pow
    let pOperatedNum = operatedNum * pow
    switch (calcuType) {
        case 'add':
            result = (pOperaNum + pOperatedNum) / pow
            break
        case 'reduce':
            result = (pOperaNum - pOperatedNum) / pow
            break
        case 'ride':
            result = (pOperaNum * pOperatedNum) / (pow * pow)
            break
        case 'except':
            if (operatedNum <= 0) {
                return 0
            } else {
                let t = (parseFloat(operaNum) / parseFloat(operatedNum)).toFixed(decPlace)
                let s = t.split('.');
                let n = s[1].toString()
                let r = Number(n.substr(0, 2)) + '.' + n.substr(2, 4);
                result = r
            }

            break
        default:
            throwError('RangeError', '计算类型参数错误', 'addDoublePrecision')
            break
    }
    return result
}

/**
 * 格式化日期
 * @param {Number | String} dateMsec 时间
 * @param {String} fmt 时间格式，例如： yyyy-MM-dd hh:mm:ss
 */
export const formatDate = (dateMsec, fmt) => {
    let date = new Date(dateMsec)
    let o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt
}



/**
 * 调用浏览器复制功能
 * @param {String} str
 * @return {Boolean} true 复制成功  false 失败
 */
export const copyStr = str => {
    if (typeof str !== 'string') {
        return false
    }
    let oInput = document.createElement('input')
    oInput.value = str
    document.body.appendChild(oInput)
    try {
        oInput.select()
        if (typeof document.execCommand === 'function') {
            document.execCommand('Copy')
            oInput.style.display = 'none'
            document.body.removeChild(oInput)
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

/**
 * 递归实现深拷贝
 * @param {Object | Array} obj
 */
export const deepClone = obj => {
    let result = Array.isArray(obj) ? [] : {}
    if (obj && typeof obj === 'object') {
        for (let key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) {
                if (obj[key] && typeof obj[key] === 'object') {
                    result[key] = deepClone(obj[key])
                } else {
                    result[key] = obj[key]
                }
            }
        }
    }
    return result
}

/**
 * JSON实现深拷贝
 * 缺点：不能识别undefined、symbol、function
 * @param {Object} obj
 */
export const jsonDeepClone = obj => {
    if (obj && typeof obj === 'object') {
        return JSON.parse(JSON.stringify(obj))
    }
}
/**
 * 判断IE版本
 * 返回false说明非IE浏览器
 */
export const checkIEVersion = () => {
    let userAgent = navigator.userAgent
    let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1
    if (isIE) {
        let reIE = new RegExp('MSIE (\\d+\\.\\d+);')
        reIE.test(userAgent)
        return parseFloat(RegExp['$1'])
    }
    return false
}

/**
 * 根据时间获取当前小时分钟，比如： 12:25 
 * @param {Date || String} 时间对象或者时间戳 
 */
export const getHourAndMin = (time) => {
    if (typeof time === 'string') {
        time = time.split('-').join('/')
    }
    time = new Date(time)
    let h = time.getHours()
    let m = time.getMinutes()
    h = h < 10 ? `0${h}` : h
    m = m < 10 ? `0${m}` : m
    return `${h}:${m}`
}

/**
 * 根据时间获取月:日 格式数据
 * @param {Date} date
 */

export const getMonthAndDay = (date) => {
    if (typeof time === 'string') {
        date = date.split('-').join('/')
    }
    date = new Date(date)
    return `${date.getMonth() + 1}月${date.getDate()}日`
}

/**
 * 根据两个时间计算时间差，返回一个对象，x年x月x日x时x分x秒的数字
 * @param {Date || String} d1
 * @param {Date || String} d2
 * @return {Object} duration
 */
export const getTwoTimeDuration = (d1, d2) => {
    // if (typeof time === 'string') {
    d1 = d1.split('-').join('/')
    d2 = d2.split('-').join('/')
    // }
    let st = new Date(d1) / 1000
    let et = new Date(d2) / 1000
    let secs = Number(et) - Number(st)
    let mins = parseInt(secs / 60)
    let hours = parseInt(mins / 60)
    let days = parseInt(hours / 24)
    let months = parseInt(days / 30)
    let years = parseInt(months / 12)

    return {
        st: st,
        et: et,
        s: secs,
        secs: secs % 60,
        mins: mins % 60,
        hours: hours % 24,
        days: days % 30,
        months: months % 12,
        years
    }
}

function getLngLat(lng, lat) {
    return new window.AMap.LngLat(lng, lat, true)
}

// 跨日期线地图坐标点
export const getAcrossLngLat = (lng1, lat1, lng2, lat2) => {
    lng1 = parseFloat(lng1)
    lat1 = parseFloat(lat1)
    lng2 = parseFloat(lng2)
    lat2 = parseFloat(lat2)
    if ((Math.abs(lng1) + Math.abs(lng2) > 179.999999) && (lng1 * lng2 < 0)) {
        return {
            lngLat: [getLngLat(lng1 > 0 ? lng1 : lng1 + 360, lat1), getLngLat(lng2 > 0 ? lng2 : lng2 + 360, lat2)],
            isAcross: 1
        }
    }
    return {
        lngLat: [getLngLat(lng1, lat1), getLngLat(lng2, lat2)]
    }
}

// 防抖函数
export const debounce = (fun, time) => {
    let timer = null
    return function () {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fun.apply(this, arguments)
        }, time);
    }
}

// 节流函数
export const throttle = (fun, time) => {
    let timer = null
    return function () {
        console.log(timer)
        if (!timer) {
            fun.apply(this, arguments)
            timer = setTimeout(() => {
                timer = null
            }, time);
        }
    }
}



// m内的随机数 整数
export const randomM = m => {
    if (m % 1 !== 0) {
        throw new TypeError('非整数')
    }
    return Math.round(m * Math.random())
}

// m-n 内的随机数  整数
export const randomMn = (m, n) => {
    if (m % 1 !== 0 || n % 1 !== 0) {
        throw new TypeError('非整数')
    }
    return Math.round((n - m) * Math.random()) + m
}

//  秒数转分钟:秒  136 to  02:16
export const seToMinSe = se => {
    if (se === 0) {
        return '00:00'
    }
    if (typeof se !== 'number' || se % 1 !== 0 || se < 0) {
        throw new TypeError('非正整数')
    }
    let m = Math.floor(se / 60)
    let s = se % 60
    m = m >= 10 ? m : `0${m}`
    s = s >= 10 ? s : `0${s}`
    return `${m}:${s}`
}

// 生成随机颜色

export const getRandomColor = (a) => {
    let r = Math.floor(Math.random() * 256);
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    a = a ? a : (Math.random() + 0.5).toFixed(2)
    //返回随机生成的颜色
    return `rgba(${r},${g},${b},${a})`
}

// 反xss转义  用于 正常转义markdown格式
export const escapeHtml = html => {
    return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
}


//生成随机数
export const getNum = () => {
    var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var nums = "";
    for (var i = 0; i < 16; i++) {
        var id = parseInt(Math.random() * 61);
        nums += chars[id];
    }
    return nums;
}


/**
* 获取url 参数
*/
export const getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null)
        return unescape(r[2]);
    return null;
}

export const formatNumLikeMoney = (strNum) => {
    if (strNum.length <= 3) {
        return strNum;
    }
    if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
        return strNum;
    }
    var a = RegExp.$1,
        b = RegExp.$2,
        c = RegExp.$3;
    var re = new RegExp();
    re.compile("(\\d)(\\d{3})(,|$)");
    while (re.test(b)) {
        b = b.replace(re, "$1,$2$3");
    }
    return a + "" + b + "" + c;
}

export const insertStr = (soure, start, newStr) => {
    return soure.slice(0, start) + newStr + soure.slice(start);
}

//获取时间相差值 date 202005
export const diffDateTime = (d, cDate) => {
    let date = d.toString();
    var now = new Date();
    if (cDate) {
        now = new Date(cDate)
    }
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let y = date.slice(0, 4);
    let m = Number(date.slice(4, 6));
    let curDate = now.getTime();
    let ms = ((year - y) * 365 + (month - m) * 30) * 24 * 3600 * 1000
    return curDate - ms
}
//获取对象某个键值的最大值 
export const getObjectMax = (obj, key) => {
    let max = 0;
    for (let k in obj) {
        let item = obj[k];
        if (key != undefined) {
            item = item[key]
        }
        if (max <= Number(item)) {
            max = item;
        }

    }
    return max;
}


